const ApolloCallsPage = () => import('@/views/ApolloCallsPage');
const ApolloClientsPage = () => import('@/views/ApolloClientsPage');

const apollo = [
  {
    path: '/apollo/apollo-tasks',
    name: 'apollo-tasks',
    component: ApolloCallsPage,
    meta: {
      layout: 'DefaultLayout',
      title: 'Apollo Tasks',
    },
  },
  {
    path: '/apollo/apollo-clients',
    name: 'apollo-clients',
    component: ApolloClientsPage,
    meta: {
      layout: 'DefaultLayout',
      title: 'Apollo client',
    },
  },
];

export default apollo;
