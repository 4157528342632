import { useHttp } from '@/middleware/httpMiddleware';

async function getFreeProxies () {
  const data = await useHttp(
    '/admin/proxies/free',
    'GET',
    null
  );

  return data.payload;
}

async function setProxyForCred (id, address) {
  const data = await useHttp(
    '/admin/proxies/set_to_cred',
    'POST',
    {
      credentials_id: id,
      proxy_address: address,
    },
    null,
    true
  );
  return data.payload;
}

async function setProxyForActiveCreds () {
  const data = await useHttp(
    '/admin/credentials/set_proxy',
    'POST'
  );
  return data.payload;
}

async function getProxies (args) {
  args.limit = 10;
  const data = await useHttp(
    '/admin/proxy',
    'GET',
    null,
    args
  );
  return data.payload;
}

async function createProxy (proxy) {
  const data = await useHttp(
    '/admin/proxy',
    'POST',
    proxy,
    null,
    true
  );
  return data.payload;
}

export {
  getFreeProxies,
  setProxyForCred,
  setProxyForActiveCreds,
  getProxies,
  createProxy,
};
