import { getEmails } from '../../api/emailsMethods';

const state = () => ({
  emailsList: [],
  filters: {
    credentials: { value: '' },
    provider: { value: '' },
    emailFilter: '',
  },
});

const getters = {
  emailsList: state => {
    return state.emailsList;
  },
  filters: state => {
    return state.filters;
  },
};

const mutations = {
  SET_EMAILS_LIST (state, payload) {
    state.emailsList = payload;
  },
  SET_FILTER (state, filter) {
    state.filters[filter.key] = filter.value;
  },
  LOAD_EMAILS_LIST (state, payload) {
    state.emailsList = [...state.emailsList, ...payload];
  },
};

const actions = {
  async SET_EMAILS_LIST ({ commit }, payload) {
    const emails = payload || await getEmails();
    commit('SET_EMAILS_LIST', emails);
    return emails;
  },
  async LOAD_EMAILS_LIST ({ commit, state }, offset = 0) {
    const {
      credentials,
      provider,
      emailFilter,
    } = state.filters;
    if (!offset) {
      commit('SET_EMAILS_LIST', []);
    }

    const emails = await getEmails(offset, credentials.value, provider.value, emailFilter);
    commit('LOAD_EMAILS_LIST', emails);

    return emails;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
