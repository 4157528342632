import Vue from 'vue';
import VueRouter from 'vue-router';

// modules
import credentials from '@/router/modules/credentials';
import problems from '@/router/modules/problems';
import tasks from '@/router/modules/tasks';
import workers from '@/router/modules/workers';
import apollo from '@/router/modules/apollo';
import emails from '@/router/modules/emails';
import proxy from '@/router/modules/proxy';
import emailsWorkflow from '@/router/modules/emailsWorkflow';
import scheduledTasks from '@/router/modules/scheduledTasks';

Vue.use(VueRouter);

const routes = [
  ...credentials,
  ...problems,
  ...tasks,
  ...workers,
  ...apollo,
  ...emails,
  ...emailsWorkflow,
  ...proxy,
  ...scheduledTasks,
  {
    path: '*', // redirect for all undefined routes
    redirect: '/credentials',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
