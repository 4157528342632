const EmailsPage = () => import('@/views/EmailsPage');

const emails = [
  {
    path: '/emails',
    name: 'Emails',
    component: EmailsPage,
    meta: {
      layout: 'DefaultLayout',
      title: 'Emails',
    },
  },
];

export default emails;
