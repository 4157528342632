import { getCredentials } from '@/api/credentialsMethods';

const state = () => ({
  credentialsList: [],
  filters: {
    worker: { value: '' },
    status: { value: '' }, // active, inactive, invalid, deleted
    q: { value: '' },
  },
});

const getters = {
  credentialsList: state => {
    return state.credentialsList;
  },
  filters: state => {
    return state.filters;
  },
};

const mutations = {
  SET_CREDENTIALS_LIST (state, payload) {
    state.credentialsList = payload;
  },
  LOAD_CREDENTIALS_LIST (state, payload) {
    state.credentialsList = [...state.credentialsList, ...payload];
  },
  UPDATE_CREDENTIALS_LIST (state, payload) {
    const existingIndex = state.credentialsList.findIndex(credential => credential._id === payload._id);
    if (existingIndex > -1) {
      state.credentialsList.splice(existingIndex, 1);
    }
    state.credentialsList.push(payload);
  },
  SET_FILTERS (state, filters) {
    state.filters = { ...state.filters, ...filters };
  },
};

const actions = {

  async SET_CREDENTIALS_LIST ({ commit }, payload) {
    const credentials = payload;
    commit('SET_CREDENTIALS_LIST', credentials);
    return credentials;
  },
  async LOAD_CREDENTIALS_LIST ({ commit, state }, offset = 0) {
    const {
      status,
      worker,
      q,
    } = state.filters;

    const credentials = await getCredentials(offset, 5, true, worker.value, status.value, q.value);

    if (!offset) {
      commit('SET_CREDENTIALS_LIST', []);
    }

    commit('LOAD_CREDENTIALS_LIST', credentials);

    return credentials;
  },
  async SET_FILTERS ({ commit, state, dispatch }, filter) {
    commit('SET_FILTERS', filter);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
