const ProxyPage = () => import('@/views/ProxyPage');

const proxy = [
  {
    path: '/proxy',
    name: 'proxy',
    component: ProxyPage,
    meta: {
      layout: 'DefaultLayout',
      title: 'Proxy',
    },
  },
];

export default proxy;
