const useHttp = async (
  url, method = 'GET', body = null, params = {},
  json = false, domain = null
) => {
  if (body) {
    if (json) {
      body = JSON.stringify(body);
    } else {
      const formData = new FormData();
      Object.keys(body).forEach(key => formData.append(key, body[key]));
      body = formData;
    }
  }
  const reqHeaders = {};
  if (json) {
    reqHeaders['Content-Type'] = 'application/json';
  }
  // headers['Content-Type'] = 'application/x-www-form-urlencoded';
  // if (reqHeaders) {
  //   reqHeaders = Object.assign(reqHeaders, headers);
  // }

  params = {
    ...params,
    token: localStorage.getItem('token'),
  };
  domain = domain || `${process.env.VUE_APP_BASE_URL}/public_api`;
  const looch = domain.includes('looch');
  if (looch) {
    reqHeaders['api-key'] = process.env.VUE_APP_LOOCH_API_KEY;
  }
  params = new URLSearchParams(params);
  url = `${domain}${url}?${params.toString()}`;
  const response = await fetch(url, {
    method,
    body,
    headers: reqHeaders,
  });
  const data = await response.json();

  if (!looch && (!response.ok || !data.ok)) {
    throw new Error(data.error || 'Something went wrong');
  }

  return data;
};

export { useHttp };
