const EmailsWorkflowPage = () => import('@/views/EmailsWorkflowPage');

const emailsWorkflow = [
  {
    path: '/emails-workflow',
    name: 'Emails workflow',
    component: EmailsWorkflowPage,
    meta: {
      layout: 'DefaultLayout',
      title: 'Emails workflow',
    },
  },
];

export default emailsWorkflow;
