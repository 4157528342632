// import { getProblems } from '@/api/problemsMethods';

const state = () => ({
  problemsList: [],
});

const getters = {
  problemsList: state => {
    return state.problemsList;
  },
};

const mutations = {
  SET_PROBLEMS_LIST (state, payload) {
    state.problemsList = payload;
  },
};

const actions = {
  async LOAD_PROBLEMS ({ commit, state }, offset = 0) {
    // const problems = await getProblems();
    const problems = [];
    commit('SET_PROBLEMS_LIST', problems);

    return problems;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
