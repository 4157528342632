import { getWorkers, getWorkersVersion } from '@/api/workersMethods';

const state = () => ({
  workersList: [],
  workersFilter: [],
  version: '1.13.13',
});

const getters = {
  workersList: state => {
    return state.workersList;
  },
  workersFilter: state => {
    return state.workersFilter;
  },
  version: state => {
    return state.version;
  },
};

const mutations = {
  SET_WORKERS_LIST (state, payload) {
    state.workersList = payload;
  },
  SET_WORKERS_FILTER (state, payload) {
    state.workersFilter = payload;
  },
  LOAD_WORKERS_LIST (state, payload) {
    state.workersList = [...state.workersList, ...payload];
  },
  SET_VERSION (state, payload) {
    state.version = payload;
  },
};

const actions = {
  async SET_WORKERS_LIST ({ commit }, payload) {
    const workers = payload;
    commit('SET_WORKERS_LIST', workers);

    return workers;
  },
  async LOAD_WORKERS_LIST ({ commit, state }, { offset, limit, isAddCreds, apollo }) {
    const workers = await getWorkers(offset, limit, isAddCreds, apollo);
    commit('LOAD_WORKERS_LIST', workers);

    return workers;
  },
  async SET_WORKERS_FILTER ({ commit, state }, offset) {
    const workers = await getWorkers(offset, 1, false);
    commit('SET_WORKERS_FILTER', workers);

    return workers;
  },

  async SET_VERSION ({ commit, state }) {
    const { version } = await getWorkersVersion();
    commit('SET_VERSION', version);

    return version;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
