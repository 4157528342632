import { useHttp } from '@/middleware/httpMiddleware';

async function checkToken () {
  const data = await useHttp(
    '/auth/validate_token',
    'GET'
  );

  return data.ok;
}

export { checkToken };
