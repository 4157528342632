import { getProxies } from '@/api/proxiesMethods';

const state = () => ({
  proxyList: [],
  filters: {},
});

const getters = {

  proxyList: state => {
    return state.proxyList;
  },
  filters: state => {
    return state.filters;
  },
};

const mutations = {

  SET_FILTERS (state, filters) {
    state.filters = { ...state.filters, ...filters };
  },
  SET_PROXY_LIST (state, payload) {
    state.proxyList = payload;
  },
  LOAD_PROXY_LIST (state, payload) {
    state.proxyList = [...state.proxyList, ...payload];
  },
};

const actions = {
  async SET_FILTERS ({ commit, state, dispatch }, filter) {
    commit('SET_FILTERS', filter);
  },
  async LOAD_PROXY_LIST ({ commit, state }, offset = 0) {
    if (!offset) {
      commit('SET_PROXY_LIST', []);
    }
    const filters = Object.entries(state.filters).reduce((prev, [k, v]) => {
      if (v) { prev[k] = v; }
      return prev;
    }, {});
    const proxies = await getProxies({ ...filters, offset });
    commit('LOAD_PROXY_LIST', proxies);

    return proxies;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
