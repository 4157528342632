const WorkersPage = () => import('@/views/WorkersPage');

const workers = [
  {
    path: '/workers',
    name: 'workers',
    component: WorkersPage,
    meta: {
      layout: 'DefaultLayout',
      title: 'Workers',
    },
  },
];

export default workers;
