import { useHttp } from '@/middleware/httpMiddleware';

async function getCredentials (offset = 0, limit = 5, is_add_stats = false,
  worker = '', status = '', q = '') {
  const data = await useHttp(
    '/admin/credentials',
    'GET',
    null,
    {
      offset,
      limit,
      is_add_stats,
      worker,
      status,
      q,
    }
  );

  return data.payload;
}
async function getChallenged () {
  const data = await useHttp(
    '/admin/credentials/get_challenged',
    'GET',
    null,
    {}
  );

  return data.payload;
}

export {
  getCredentials,
  getChallenged,
};
