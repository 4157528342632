const ProblemsPage = () => import('@/views/ProblemsPage');

const problems = [
  {
    path: '/problems',
    name: 'problems',
    component: ProblemsPage,
    meta: {
      layout: 'DefaultLayout',
      title: 'Problems',
    },
  },
];

export default problems;
