import { useHttp } from '@/middleware/httpMiddleware';

async function getWorkers (offset = 0, limit = 100, isAddCreds = true, apollo = false) {
  const data = await useHttp(
    '/admin/workers',
    'GET',
    null,
    {
      offset,
      limit,
      is_add_creds: isAddCreds,
      apollo,
    }
  );
  return data.payload;
}

async function updateWorker (workerId, isAll = false, status) {
  const data = await useHttp(
    '/admin/workers/update',
    'POST',
    {
      worker_id: workerId,
      is_all: isAll,
      status,
    },
    null,
    true
  );
  return data.payload;
}

async function getFreeWorkers () {
  const data = await useHttp(
    '/admin/workers/free',
    'GET',
    null
  );

  return data.payload;
}
async function getWorkersVersion () {
  const data = await useHttp(
    '/admin/workers/get_version',
    'GET',
    null
  );

  return data.payload;
}

export {
  getWorkers,
  updateWorker,
  getFreeWorkers,
  getWorkersVersion,
};
