import { useHttp } from '@/middleware/httpMiddleware';

async function getApolloClients (offset = 0, limit = 500) {
  if (process.env.VUE_APP_BASE_URL.includes('dev')) {
    return [];
  }
  const params = {
    skip: offset,
    limit: limit > 0 ? limit : null,
  };
  const data = await useHttp('/admin/accounts', 'GET', null, params, true, 'https://api.looch.io');

  return data;
}

async function getApolloWorkers () {
  const data = await useHttp('/admin/workers', 'GET', null, {}, true, 'https://api.looch.io');

  return data;
}
async function getApolloTasks (params) {
  params.offset = 0;
  const data = await useHttp('/admin/tasks/apollo', 'GET', null, params, true, 'https://api.looch.io');

  return data;
}
function retryApolloTask (id) {
  return useHttp(`/admin/tasks/${id}/retry`, 'POST', null, null, true, 'https://api.looch.io'); ;
}
async function getApolloCredits () {
  const data = await useHttp(
    '/admin/accounts/credits',
    'GET',
    null,
    null,
    true,
    'https://api.looch.io'
  );

  return data;
}
export { getApolloClients, getApolloWorkers, getApolloTasks, getApolloCredits, retryApolloTask };
