import { getApolloClients } from '@/api/apolloMethods';

const state = () => ({
  apolloClients: [],
  filtersCalls: {
    credentials: { value: '' },
    client: { value: '' },
  },
});

const getters = {
  apolloClients: state => {
    return state.apolloClients;
  },
};

const mutations = {
  SET_APOLLO_CLIENTS_LIST (state, payload) {
    state.apolloClients = payload;
  },
  LOAD_CLIENTS_LIST (state, payload) {
    state.apolloClients = [...state.apolloClients, ...payload];
  },
};

const actions = {
  async SET_APOLLO_CLIENTS_LIST ({ commit, state }, payload) {
    if (state.apolloClients.length) {
      return state.apolloClients;
    }
    const apolloClients = payload || await getApolloClients();
    commit('SET_APOLLO_CLIENTS_LIST', apolloClients);
    return apolloClients;
  },
  async LOAD_CLIENTS_LIST ({ commit, state }, offset = 0) {
    if (!offset) {
      commit('SET_APOLLO_CLIENTS_LIST', []);
    }
    let clients = [];
    clients = await getApolloClients(offset);

    commit('LOAD_CLIENTS_LIST', clients);

    return clients;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
