import { useHttp } from '@/middleware/httpMiddleware';

async function getTasks (offset = 0, worker = '', credentials = '', status = '', type = '', workflow = '') {
  const data = await useHttp(
    '/admin/tasks',
    'GET',
    null,
    {
      offset,
      worker,
      credentials,
      status,
      type,
      workflow,
    }
  );

  return data.payload;
}
async function getScheduledTasks (offset = 0, { status, credentials, workflow }) {
  const data = await useHttp(
    '/admin/schedule_tasks',
    'GET',
    null,
    {
      offset,
      status,
      credentials,
      workflow,
    }
  );

  return data.payload;
}

async function createTask (credentials, taskType, taskData) {
  const data = await useHttp(
    '/admin/create_task',
    'POST',
    {
      credentials,
      task_type: taskType,
      task_data: JSON.stringify(taskData),
    }
  );

  return data.payload;
}

async function deleteTask (id) {
  const data = await useHttp(
    `/admin/tasks/${id}/delete`,
    'POST'
  );

  return data.payload;
}

async function retryTask (id) {
  const data = await useHttp(
    `/admin/tasks/${id}/retry`,
    'POST'
  );

  return data.payload;
}
export {
  getTasks,
  createTask,
  deleteTask,
  getScheduledTasks,
  retryTask,
};
