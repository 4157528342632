const CredentialsPage = () => import('@/views/CredentialsPage');

const credentials = [
  {
    path: '/credentials',
    name: 'credentials',
    component: CredentialsPage,
    meta: {
      layout: 'DefaultLayout',
      title: 'Credentials',
    },
  },
];

export default credentials;
