import { getScheduledTasks } from '@/api/tasksMethods';

const state = () => ({
  tasksList: [],
  filters: {
    workflow: { value: '' },
    credentials: { value: '' },
    status: { value: '' },
  },
});

const getters = {
  tasksList: state => {
    return state.tasksList;
  },
  filters: state => {
    return state.filters;
  },

};

const mutations = {
  SET_TASKS_LIST (state, payload) {
    state.tasksList = payload;
  },
  LOAD_TASKS_LIST (state, payload) {
    state.tasksList = [...state.tasksList, ...payload];
  },

  SET_FILTERS (state, filters) {
    state.filters = { ...state.filters, ...filters };
  },
};

const actions = {
  async SET_TASKS_LIST ({ commit }, payload) {
    const tasks = payload;
    commit('SET_TASKS_LIST', tasks);

    return tasks;
  },

  async LOAD_TASKS_LIST ({ commit, state }, offset = 0) {
    if (!offset) {
      commit('SET_TASKS_LIST', []);
    }
    const filters = Object.entries(state.filters).reduce((prev, [k, v]) => {
      prev[k] = v.value || '';
      return prev;
    }, {});
    const tasks = await getScheduledTasks(offset, filters);
    commit('LOAD_TASKS_LIST', tasks);
    return tasks;
  },

  SET_FILTERS ({ commit, state, dispatch }, filters) {
    commit('SET_FILTERS', filters);
  },

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
