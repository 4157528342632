const ScheduleTasksPage = () => import('@/views/ScheduleTasksPage');

const scheduledTasks = [
  {
    path: '/schedule',
    name: 'schedule',
    component: ScheduleTasksPage,
    meta: {
      layout: 'DefaultLayout',
      title: 'Schedule Tasks',
    },
  },
];

export default scheduledTasks;
