const TasksPage = () => import('@/views/TasksPage');
const CreateTaskPage = () => import('@/views/CreateTaskPage');

const tasks = [
  {
    path: '/tasks',
    name: 'tasks',
    component: TasksPage,
    meta: {
      layout: 'DefaultLayout',
      title: 'Tasks',
    },
  },
  {
    path: '/tasks/create',
    name: 'taskCreate',
    component: CreateTaskPage,
    meta: {
      layout: 'DefaultLayout',
      title: 'Create task',
    },
  },
];

export default tasks;
