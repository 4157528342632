import { useHttp } from '@/middleware/httpMiddleware';

async function getEmails (offset = 0, credentials = '', provider = '', email_filter = '') {
  const data = await useHttp('/profiles/emails', 'GET', null, {
    offset,
    credentials,
    provider,
    email_filter,
  });

  return data.payload;
}

export { getEmails };
