import { getTasks, deleteTask, retryTask } from '@/api/tasksMethods';

const state = () => ({
  tasksList: [],
  filters: {
    worker: { value: '' },
    workflow: { value: '' },
    credentials: { value: '' },
    type: { value: '' },
    status: { value: '' },
  },
});

const getters = {
  tasksList: state => {
    return state.tasksList;
  },
  filters: state => {
    return state.filters;
  },
};

const mutations = {
  SET_TASKS_LIST (state, payload) {
    state.tasksList = payload;
  },
  LOAD_TASKS_LIST (state, payload) {
    state.tasksList = [...state.tasksList, ...payload];
  },
  SET_FILTERS (state, filters) {
    state.filters = { ...state.filters, ...filters };
  },

};

const actions = {
  async SET_TASKS_LIST ({ commit }, payload) {
    const tasks = payload;
    commit('SET_TASKS_LIST', tasks);

    return tasks;
  },
  async DELETE_TASK ({ commit, state }, payload) {
    const tasksList = state.tasksList.slice(0);
    const index = state.tasksList.findIndex((task) => task._id === payload);
    if (index > -1) {
      tasksList.splice(index, 1);
      commit('SET_TASKS_LIST', tasksList);
      await deleteTask(payload);
    }
  },
  async RETRY_TASK ({ commit, state }, payload) {
    const tasksList = state.tasksList.slice(0);
    const newTask = await retryTask(payload);
    tasksList.unshift(newTask);
    commit('SET_TASKS_LIST', tasksList);
  },
  async LOAD_TASKS_LIST ({ commit, state }, offset = 0) {
    const {
      status,
      worker,
      credentials,
      type,
      workflow,
    } = state.filters;

    if (!offset) {
      commit('SET_TASKS_LIST', []);
    }

    const tasks = await getTasks(offset, worker.value, credentials.value, status.value, type.value, workflow.value);
    commit('LOAD_TASKS_LIST', tasks);

    return tasks;
  },

  SET_FILTERS ({ commit, state, dispatch }, filters) {
    commit('SET_FILTERS', filters);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
